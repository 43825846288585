
import {
    
} from '@ant-design/icons-vue';

export default {
    components: {
    },
    data() {
        return {
            // 列表数据
            data: [],
            // 搜索表单
            search: {
                keyword: ''
            },
            // 分页参数
            page: {
                page: 0,
                limit: 10
            },
            // 列表加载状态
            loading: false
        };
    },
    mounted() {
        this.query();
    },
    methods: {
        /**
         * 查询数据
         */
        query() {
            this.loading = true;

            this.page.page++;

            this.$http.get('/user/notice/list', {
                params: Object.assign({}, this.search, this.page)
            }).then(res => {
                this.loading = false;

                if (res.data.code === 0) {
                    this.data = this.data.concat(res.data.data);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.loading = false;
                this.$message.error(e.message);
            });
        }
    }
}
